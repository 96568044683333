import { Component,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { StrikeData } from 'src/app/model/strikedata';
import { UserService } from 'src/app/services/user.service';
import { TradeComponent } from '../../trade/trade.component';
import { EquityOrderDetails } from 'src/app/model/equityOrderDetails';
import { EquityService } from 'src/app/services/equity.service';

@Component({
  selector: 'app-equitydailog',
  templateUrl: './equitydailog.component.html',
  styleUrls: ['./equitydailog.component.css']
})
export class EquitydailogComponent {
  constructor(private router:Router, private dialog:MatDialog, @Inject(MAT_DIALOG_DATA)
  public data: { title: string; message: string; 
    orderId:number,
    portfolioId:number,
    portfolioName:string,
    entryPrice: number,
    exitPrice: number,
    returnPercent:number,
    createdDate:string,
    transactionType:string,
    tradingsymbol:string,
    orderStatus:string
    operationType:string,
    category:string,
    exitType:boolean,
    strikeData:StrikeData   
  },
  private equityService:EquityService, private userService:UserService,private tradeComponent:TradeComponent) {
    this.tradeDialogForm.get('inputExitPrice')?.setValue(this.data.exitPrice);
    this.tradeDialogForm.get('selectedStatus')?.setValue(this.data.orderStatus);
    this.tradeDialogForm.get('inputEntryPrice')?.setValue(this.data.entryPrice);
    this.tradeDialogForm.get('inputCategory')?.setValue(this.data.category);
  }
 
  
  orderDetails: EquityOrderDetails = new EquityOrderDetails();
 
  tradeDialogForm=new FormGroup(
    {
      inputExitPrice:new FormControl(),
      selectedStatus:new FormControl(),
      inputEntryPrice:new FormControl(),
      inputCategory:new FormControl()
    }
  );

  onTradeSubmit(operationType:string){
    
    console.log("submitting the form with transactionType: "+this.data.transactionType   
    + ",operationType::"+operationType
    );   
    this.orderDetails._id = this.data.orderId; 
    this.orderDetails.transactionType = this.data.transactionType;
    this.orderDetails.tradingsymbol = this.data.tradingsymbol;
    this.orderDetails.portfolioId = this.data.portfolioId;
    this.orderDetails.portfolioName = this.data.portfolioName;
    this.orderDetails.triggerPrice = this.data.entryPrice;
    this.orderDetails.exitPrice = this.tradeDialogForm.value.inputExitPrice;
    this.orderDetails.returnPercent = this.data.returnPercent;
    this.orderDetails.createdDate = this.data.createdDate;
    this.orderDetails.agentId = Number(sessionStorage.getItem('userId'));
    this.orderDetails.orderStatus =  this.tradeDialogForm.value.selectedStatus;
    this.orderDetails.triggerPrice =  this.tradeDialogForm.value.inputEntryPrice; 
    this.orderDetails.category =  this.tradeDialogForm.value.inputCategory; 
    if(operationType == "ENTRY"){
      this.equityService.processEntryOrder(this.orderDetails).subscribe((res: any) => {
          this.closeDialogBox();
      });   
    }else if(operationType == "MODIFY"){
      this.equityService.processModifyOrder(this.orderDetails).subscribe((res: any) => {
        this.closeDialogBox();
      });    
   }
  }

  closeDialogBox(){
    this.dialog.closeAll();    
  }
}