<div mat-dialog-title class="dialog-title">
    {{ data.title }}
    <span class="close">
        <mat-icon mat-dialog-close>close-icon</mat-icon>
    </span>  
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <!-- <mat-divider></mat-divider> -->
    <form [formGroup]="tradeDialogForm">        
    <div class="form-row">
      <mat-form-field  class="form-element">
        <mat-label>Status</mat-label>
        <mat-select matNativeControl required formControlName="selectedStatus">
          <mat-option value="OPEN">Open</mat-option>
          <mat-option value="CLOSE">Close</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="form-element" *ngIf="tradeDialogForm.get('selectedStatus')?.value === 'CLOSE'">
        <mat-label>Exit Price</mat-label>
        <input matInput formControlName="inputExitPrice">
      </mat-form-field> 
      <mat-form-field appearance="outline" class="form-element" *ngIf="tradeDialogForm.get('selectedStatus')?.value === 'OPEN'">
        <mat-label>Entry Price</mat-label>
        <input matInput formControlName="inputEntryPrice">
      </mat-form-field> 
      <mat-form-field appearance="outline" class="form-element" *ngIf="tradeDialogForm.get('selectedStatus')?.value === 'OPEN'">
        <mat-label>Category</mat-label>
        <input matInput formControlName="inputCategory">
      </mat-form-field> 
     
  </div>     
  </form>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions align="end" class="dialog-actions">
    <button mat-flat-button color="primary"  (click)="onTradeSubmit(data.operationType)">Submit</button>
    <button mat-stroked-button color="warn" mat-dialog-close>Close</button>
  </mat-dialog-actions>
  