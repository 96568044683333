import { StrikeData } from "./strikedata";

export class EquityOrderDetails{
    _id!:number;
    agentId!:number;
    portfolioId!:number;
    portfolioName!:string;
    strikeData!:StrikeData;
    transactionType!:string;
    triggerPrice!:number;
    tradingsymbol!:string;
    category!:string;
    instrument_token!:number;
    exchangeType!:string;
    exitPrice!:number;
    returnPercent!:number;
    orderStatus!:string;
    createdDate!:string;
    exitDate!:string;
    isLOExist!:boolean;  
}